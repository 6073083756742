import {
  ThemeSwitcher,
  ThemeSwitcherTheme,
  ChurchAccessTokenProvider,
} from "@apollosproject/canvas-ui-web";
import { graphql } from "@apollosproject/cluster-client";
import { json } from "@remix-run/node";
import { type LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useLoaderData } from "@remix-run/react";
import { useEffect, useRef } from "react";

import { analytics } from "../../analytics";
import { query } from "../../cluster.server";
import { Navbar } from "../../components/Navbar";
import { isLoggedIn, getSession } from "../../session.server";

export const getCurrentChurch = graphql(/* GraphQL */ `
  query getCurrentChurch {
    currentChurch {
      theme
      ...NavbarChurchFragment
    }
  }
`);

export const getCurrentUserSharedProfile = graphql(/* GraphQL */ `
  query getCurrentUserSharedProfile($loggedIn: Boolean!, $slug: String!) {
    currentUser @include(if: $loggedIn) {
      ...NavbarUserFragment
      id
      originId
      originType
      profile {
        id
        firstName
        lastName
        nickName
        email
      }
      connectedChurchProfile(churchSlug: $slug) {
        id
      }
    }
  }
`);

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  if (!params.handle) throw new Error("No handle provided");

  const loggedIn = await isLoggedIn(request);
  const session = await getSession(request);
  const accessToken = session.get("accessToken") || null;

  const slug = params.handle.replace(/-/g, "_");

  const currentChurchPromise = query({
    query: getCurrentChurch,
    church: params.handle,
    request,
  });

  // TODO: combine into one graphql request.
  // Can't do that currently b/c this query has to be ran without a church header
  const currentUserPromise = query({
    query: getCurrentUserSharedProfile,
    variables: { loggedIn, slug },
    request,
  });

  const [currentChurchData, currentUserData] = await Promise.all([
    currentChurchPromise,
    currentUserPromise,
  ]);

  const theme = JSON.parse(
    currentChurchData.currentChurch?.theme ?? "{}"
  ) as ThemeSwitcherTheme;

  return json({
    currentChurch: {
      ...currentChurchData.currentChurch,
    },
    accessToken,
    theme,
    currentUser: currentUserData.currentUser,
    connectedChurchProfile: currentUserData.currentUser?.connectedChurchProfile,
  });
};

export default function Handle() {
  const data = useLoaderData<typeof loader>();
  const lastProfileRef = useRef<string>();

  useEffect(() => {
    if (
      data.currentUser?.profile?.id &&
      lastProfileRef.current !== data.currentUser?.profile?.id
    ) {
      lastProfileRef.current = data.currentUser?.profile?.id;
      analytics.identify(data.currentUser?.profile?.id, {
        church: "global",
        email: data.currentUser.profile?.email,
        firstName: data.currentUser.profile?.firstName,
        lastName: data.currentUser.profile?.lastName,
        nickName: data.currentUser.profile?.nickName,
        userId: data.currentUser.profile?.id,
        originId: data.currentUser?.originId,
        originType: data.currentUser?.originType,
        apollosId: data.currentUser?.profile?.id,
      });
    }
  }, [
    data.currentUser?.profile?.id,
    data.currentUser?.profile?.email,
    data.currentUser?.profile?.firstName,
    data.currentUser?.profile?.lastName,
    data.currentUser?.profile?.nickName,
    data.currentUser?.originId,
    data.currentUser?.originType,
  ]);

  return (
    <ChurchAccessTokenProvider
      church={data.currentChurch?.slug || "default"}
      accessToken={data?.accessToken}
    >
      <ThemeSwitcher theme={data.theme}>
        <Navbar
          currentChurch={data.currentChurch ?? undefined}
          currentUser={data.currentUser ?? undefined}
          currentUserCanJoinChurch={!data.connectedChurchProfile}
        />
        <Outlet />
      </ThemeSwitcher>
    </ChurchAccessTokenProvider>
  );
}
